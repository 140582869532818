import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import {Icon} from "react-fa";
import Link from "../../components/Link";
import ContactForm from "../../components/ContactForm";

export default class ContactUs extends React.Component {
    render() {

        return (
            <div className="module_contact_us">
                <div className="replacement-page-title">
                    <Row className="big-row">
                        <Col xs={{span: 22}} md={{span: 18}} lg={{span: 12}}>
                            <h1>{this.props.data.replacement_page_title}</h1>
                        </Col>
                    </Row>
                </div>
                <div className="contact-strip">
                    <Row className="contact-block">

                        <ContactForm />

                        <div className="social">
                            <h5>Let's get social</h5>
                            <Link to="https://www.facebook.com/wearebrew/"><Icon size="2x" name="facebook" /></Link>
                            <Link to="https://www.instagram.com/hiwearebrew/"><Icon size="2x" name="instagram" /></Link>
                            <Link to="https://www.behance.net/wearebrew"><Icon size="2x" name="behance" /></Link>
                            <Link to="https://www.linkedin.com/company/wearebrew/"><Icon size="2x" name="linkedin" /></Link>
                            <Link to="https://twitter.com/hiwearebrew"><Icon size="2x" name="twitter" /></Link>
                        </div>
                    </Row>
                </div>
                <div className="team-image" style={{backgroundImage: `url(${this.props.data.team_image.localFile.childImageSharp.original.src})`}}></div>
            </div>
        );
    }
}
