import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import ExternalContent from "../../components/ExternalContent";

export default class ImageGridContent extends React.Component {
    render() {
        return (
            <div className="module_image_grid_content">
                <Row className="big-row">
                    <ExternalContent>
                        <div className="blurb" dangerouslySetInnerHTML={{__html: this.props.content.content }} />
                    </ExternalContent>
                    <Col className="image_grid">
                        {this.props.content.image_grid && this.props.content.image_grid.map((image) => {
                            if(image.localFile){
                                if(image.localFile.childImageSharp.original.width > image.localFile.childImageSharp.original.height*1.5) {
                                    return <div key={image.id} className="image landscape">
                                        <img alt={image.alt_text} title={image.alt_text} src={image.localFile.childImageSharp.original.src}/>
                                    </div>
                                } else {
                                    return <div key={image.id} className="image portrait">
                                        <img alt={image.alt_text} title={image.alt_text} src={image.localFile.childImageSharp.original.src}/>
                                    </div>
                                }
                            }
                        })}
                    </Col>
                </Row>
            </div>
        );
    }
}
