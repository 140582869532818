import React from 'react';
import './style.less';
import { Row } from 'antd';

export default class WhoWeAre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
        };
    }

    render() {
        return (
            <div className="module_who_we_are">
                <Row className="big-row">
                    <div className="stacked_bands">
                        <div className="cta_band">
                            <h2>Fancy a brew?</h2>
                            <p>
                                <a className="arrow-link" href="/contact-us">Let’s
                                chat</a>
                                <br />
                                <img src={this.props.content.cta_image.localFile.childImageSharp.original.src} alt="" />
                            </p>
                        </div>
                        <div className="blurb_band" dangerouslySetInnerHTML={{__html: this.props.content.blurb }} ></div>
                    </div>
                    <div className="image_grid">
                        {this.props.content.gallery_images && this.props.content.gallery_images.map((image) => {
                            return <div key={image.id} className="image" style={{backgroundImage: `url(${image.localFile.childImageSharp.original.src})`}}>
                            </div>
                        })}
                    </div>
                </Row>
            </div>
        );
    }
}
