import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import ExternalContent from "../../components/ExternalContent";

export default class ImageContent extends React.Component {
    render() {
        var count = 0;
        return (
            <div className="module_image_content">
                <Row>
                    <span className="accent-corner"></span>
                    {this.props.rows && this.props.rows.map((row) => {
                        count++;
                        return <Col key={row.image.id} xs={{span: 24}} md={{span: 12}}>
                            <div className="image" style={{backgroundImage: `url(${row.image.localFile.childImageSharp.original.src})`}}><span>0{count}</span></div>
                            <ExternalContent>
                                <div className="content" dangerouslySetInnerHTML={{__html: row.content }} />
                            </ExternalContent>
                        </Col>
                    })}
                </Row>
            </div>
        );
    }
}
