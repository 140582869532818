import React from 'react';
import './style.less';
import { Row, Carousel } from 'antd';
import { Textfit } from 'react-textfit';

export default class BrewSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slides: null,
        };
    }

    render() {
        let count = 0;
        return (
            <div className="module_brew_slider">
                <Row className="big-row">
                    <div className="slider-strapline">
                        <span>{this.props.slides.slider_strapline}</span>
                    </div>
                    <span className="accent-corner"></span>
                    <Carousel dots arrows autoplay autoplaySpeed={8000}  swipe swipeToSlide>
                        {this.props.slides.slides && this.props.slides.slides.map((slide) => {
                            count++
                            return <div className="slide" key={'slide_' + count}>
                                <h2>0{ count }</h2>
                                <h3><Textfit>{ slide.slide_title }</Textfit></h3>
                                <p dangerouslySetInnerHTML={{__html: slide.slide_blurb }} />
                            </div>
                        })}
                    </Carousel>
                    <span className="accent-line"></span>
                </Row>
            </div>
        );
    }
}
