import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import ExternalContent from "../../components/ExternalContent";

export default class StandardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html: null,
        };
    }

    render() {
        return (
            <div className={this.props.html.html_class + ' module_standard_content'} id={this.props.html.html_id}>
                <Row>
                    <Col xs={{span: 24}}>
                        <ExternalContent>
                            <div dangerouslySetInnerHTML={{__html: this.props.html.content }} />
                        </ExternalContent>
                    </Col>
                </Row>
            </div>
        );
    }
}
