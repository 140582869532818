import React from 'react'

import Wrapper from './components/Wrapper.js';

// import Accordion from "./modules/Accordion";
// import Banner from './modules/Banner';
import BrewHomeBanner from './modules/BrewHomeBanner';
import BrewSlider from './modules/BrewSlider';
import BrewWorkSlider from './modules/BrewWorkSlider';
// import CardRow from "./modules/CardRow";
import ContactUs from "./modules/ContactUs";
import IconLinks from './modules/IconLinks';
import FullWidthImage from './modules/FullWidthImage';
import HorizontalTabs from './modules/HorizontalTabs';
import ImageContent from './modules/ImageContent';
import ImageGridContent from './modules/ImageGridContent';
import MemberListing from './modules/MemberListing';
import PageTitle from './modules/PageTitle';
import PostListing from './modules/PostListing';
import StandardContent from './modules/StandardContent';
import WhoWeAre from './modules/WhoWeAre';
import YoutubeEmbed from './modules/YoutubeEmbed';

import { graphql } from 'gatsby';
import LandingPage from "./modules/LandingPage";

class PageBuilder extends React.Component {
    render() {
        let modules = buildModules(this.props.data.wordpressPage.acf.modules_page)
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = acfFields.page_title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                {modules.map((module) => {
                    return module;
                })}
            </Wrapper>
        )
    }
}

function buildModules (modules){
    let response = [];
    for (let module in modules){

        const moduleType = modules[module].__typename;
        let content = '';

        switch (moduleType){

            // case 'WordPressAcf_accordion_content':
            //     content = <Accordion key={modules[module].id} slides={modules[module].accordion}/>
            // break;

            // case 'WordPressAcf_banners':
            //     content = <Banner key={modules[module].id} banners={modules[module].banner}/>
            // break;

            case 'WordPressAcf_brew_home_banner':
                content = <BrewHomeBanner key={modules[module].id} strapline={modules[module].strapline}/>
            break;

            case 'WordPressAcf_brew_slider':
                content = <BrewSlider key={modules[module].id} slides={modules[module]}/>
            break;

            case 'WordPressAcf_brew_work_slider':
                content = <BrewWorkSlider key={modules[module].id} slides={modules[module]}/>
            break;

            // case 'WordPressAcf_card_row':
            //     content = <CardRow key={modules[module].id} cards={modules[module]}/>
            // break;

            case 'WordPressAcf_contact_us':
                content = <ContactUs key={modules[module].id} data={modules[module]}/>
            break;

            case 'WordPressAcf_full_image':
                content = <FullWidthImage key={modules[module].id} data={modules[module]} />
            break;

            case 'WordPressAcf_horizontal_tabs':
                content = <HorizontalTabs key={modules[module.id]} data={modules[module]} />
            break;

            case 'WordPressAcf_icon_links':
                content = <IconLinks key={modules[module].id} content={modules[module]} />
            break;

            case 'WordPressAcf_image_content':
                content = <ImageContent key={modules[module].id} rows={modules[module].row} />
            break;

            case 'WordPressAcf_image_grid_content':
                content = <ImageGridContent key={modules[module].id} content={modules[module]} />
            break;

            case 'WordPressAcf_landing_page':
                content = <LandingPage key={modules[module].id} data={modules[module]}/>
            break;

            case 'WordPressAcf_page_title':
                content = <PageTitle key={modules[module].id} title={modules[module].page_title} />
            break;

            case 'WordPressAcf_post_listing':
                content = <PostListing key={modules[module].id} count={modules[module].posts_to_show} />
            break;

            case 'WordPressAcf_standard_content':
                content = <StandardContent key={modules[module].id} html={modules[module]}/>
            break;

            case 'WordPressAcf_team_member_listing':
                content = <MemberListing key={modules[module].id}/>
            break;

            case 'WordPressAcf_who_we_are':
                content = <WhoWeAre key={modules[module].id} content={modules[module]}/>
            break;

            case 'WordPressAcf_youtube_embed':
                content = <YoutubeEmbed key={modules[module].id} html={modules[module]}/>
                break;

            default:
                // Unknown module

            break;
        }
        if (content) response.push(content);
    }
    return response;
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      acf {
        page_title
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }        
        modules_page {
          __typename
          ... on WordPressAcf_brew_home_banner {
            id
            strapline
          }
          ... on WordPressAcf_brew_slider {
            id
            slider_strapline
            slides {
              slide_title
              slide_blurb
            }
          }
          ... on WordPressAcf_brew_work_slider {
            id
            slider_title
            case_studies {
              post_name
              acf {
                project_strapline
                project_image {
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 500) {
                        ... GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                project_colour
                services {
                  value
                  label
                }
              }
            }
          }
          ... on WordPressAcf_contact_us {
            id
            replacement_page_title
            team_image {
                localFile {
                  childImageSharp {
                    original {
                      width
                      height
                      src
                    }
                    fluid(quality: 90) {
                      ... GatsbyImageSharpFluid
                    }
                  }
                }
              }
          }
          ... on WordPressAcf_full_image {
            id
            html_id
            html_class
            image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxWidth: 1440) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_horizontal_tabs {
            id
            block_title
            tabs {
              tab_title
              tab_content {
                block_icon {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 512, maxHeight: 512) {
                        ... GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                block_title
                block_text
              }
            }
          }
          ... on WordPressAcf_icon_links {
            id
            title
            icons {
              icon {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                      ... GatsbyImageSharpFluid
                    }
                  }
                }
              }
              label
              linked_page
            }
          }
          ... on WordPressAcf_image_content {
            id
            row {
              content
              image {
              id
                localFile {
                  childImageSharp {
                    original {
                      width
                      height
                      src
                    }
                    fluid(maxWidth: 640) {
                      ... GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }  
          }
          ... on WordPressAcf_image_grid_content {
            id
            content
            image_grid {
              id
              alt_text
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 100) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          } 
          ... on WordPressAcf_page_title {
            id
            page_title
          }
          ... on WordPressAcf_post_listing {
            id
            posts_to_show
          }
          ... on WordPressAcf_standard_content {
            id
            content
            html_id
            html_class
          }
          ... on WordPressAcf_team_member_listing {
            id
          }         
          ... on WordPressAcf_who_we_are {
            id
            cta_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            blurb
            gallery_images {
              id
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_youtube_embed {
            id
            video_id
            html_id
            html_class
          }
          ... on WordPressAcf_landing_page {
            id
            page_title
            strapline
            work_with_logos {
              id
              alt_text
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 100) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
            case_study_title
            case_study_intro
            case_studies {
              post_name
              wordpress_id
              acf {
                project_strapline
                project_image {
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 500) {
                        ... GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                project_colour
                services {
                  value
                  label
                }
              }
            }
            outro_title
            outro_copy
          }            
        }
      }
    }
  }
`

export default PageBuilder
