import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import ExternalContent from "../../components/ExternalContent";
import Img from "gatsby-image";
import Link from "../../components/Link";
import LandingContactForm from "../../components/LandingContactForm";

export default class LandingPage extends React.Component {
    render() {

        return (
            <div className="landing_page social">
                <div className="module_page_title">
                    <Row className="big-row">
                        <Col xs={{span: 22}} md={{span: 12}} lg={{span: 10}}>
                            <h1>{this.props.data.page_title}</h1>
                            <p>{this.props.data.strapline}</p>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 10, push: 2}} lg={{push:4}}>
                            <LandingContactForm/>
                        </Col>
                    </Row>
                </div>
                <div className="module_image_grid_content">
                    <Row>
                        <ExternalContent>
                            <div className="blurb">
                                <h2 className="underline">Who we work with</h2>
                            </div>
                        </ExternalContent>
                        <Col className="image_grid">
                            {this.props.data.work_with_logos && this.props.data.work_with_logos.map((image) => {
                                if(image.localFile){
                                    return <div key={image.id} className="image landscape">
                                        <img alt={image.alt_text} title={image.alt_text} src={image.localFile.childImageSharp.original.src}/>
                                    </div>
                                }
                            })}
                        </Col>
                        <ExternalContent>
                            <div className="blurb">
                                <h2 className="underline">{this.props.data.case_study_title}</h2>
                                <p>{this.props.data.case_study_intro}</p>
                            </div>
                        </ExternalContent>
                    </Row>
                </div>

                <div className="case-study-listing">
                    {this.props.data.case_studies && this.props.data.case_studies.map((cs) => {
                        return (
                            <div key={"cs-"+cs.wordpress_id} className="case-study-item" style={{backgroundColor: cs.acf.project_colour}}>
                                <Row>
                                    <Col xs={{span:24}} md={{span:14}}>
                                        <h2 className="h3"><a href={"/our-work/"+cs.post_name}>{cs.acf.project_strapline}</a></h2>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span:10}}>
                                        <p className="services">{cs.acf.services && cs.acf.services.map((service) => {
                                            return <span key={'csli-key-'+service.value}>{service.label}</span>
                                        })}</p>
                                        <a href={"/our-work/"+cs.post_name} className="project-image">
                                            <Img fluid={cs.acf.project_image.localFile.childImageSharp.fluid} />
                                        </a>
                                        <Link to={`/our-work/${cs.post_name}/`} className="more">Read more</Link>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>

                <div className="case-study-wrapper" style={{backgroundColor: "#000"}}>
                    <div className="outcome-block">
                        <Row>
                            <Col xs={{span:24}}>
                                <h2>{this.props.data.outro_title}</h2>
                                <ExternalContent>
                                    <p>Are you ready to collaborate on social media that ticks all the boxes for your business? <Link to="/contact-us/">Contact us</Link> today, we'll get the kettle on. </p>
                                </ExternalContent>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row>
                    <div className="further-actions">
                        <a className="contact" href="/contact-us/">
                            <span className="block">Work with us?</span>
                            <span className="arrow">Contact us</span>
                        </a>
                    </div>
                </Row>
            </div>
        );
    }
}
