import React from 'react';
import './style.less';
import { Row, Col, Tabs } from 'antd';
import Img from "gatsby-image";
const TabPane = Tabs.TabPane;

export default class HorizontalTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
    }

    render() {
        let count = 0;
        return (
            <div className="module_horizontal_tabs">
                <div className="h3-wrap">
                    <Row>
                        <Col>
                            <h3>{ this.props.data.block_title }</h3>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="1">
                            {this.props.data.tabs && this.props.data.tabs.map((tab) => {
                                count++;
                                return  <TabPane tab={tab.tab_title} key={ count }>
                                        {tab.tab_content && tab.tab_content.map((content) => {
                                            return <React.Fragment>
                                                <hr/>
                                                <div className="content-block">
                                                    { content.block_icon.localFile !== null ?
                                                        <div className="icon-holder">
                                                            <Img fluid={content.block_icon.localFile.childImageSharp.fluid} />
                                                        </div>
                                                    : null }
                                                    <div className="content">
                                                        <h4>{content.block_title}</h4>
                                                        <div dangerouslySetInnerHTML={{__html: content.block_text }} />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })}
                                </TabPane>
                            })}
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}
