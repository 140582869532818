import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import Img from "gatsby-image";

export default class FullWidthImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html: null,
        };
    }

    render() {
        return (
            <div className={this.props.data.html_class + ' module_full_width_image'} id={this.props.data.html_id}>
                <Row>
                    <Col xs={{span: 24}} md={{span: 20, offset: 2}}>
                        <Img fluid={this.props.data.image.localFile.childImageSharp.fluid} />
                    </Col>
                </Row>
            </div>
        );
    }
}
