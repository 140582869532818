import React from 'react';
import './style.less';
import {Row, Col} from 'antd';
import {graphql, StaticQuery} from "gatsby";
import Link from "../../components/Link";
import Img from 'gatsby-image'

class MemberListing extends React.Component {
    state = {
        department : "all"
    };

    selectDepartment = (dep) => {
        this.setState({
            department : dep
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="filter team">
                    <h4 className="filter-title">Filter by department</h4>
                    <ul className="filter-list">
                        <li>
                            <button data-active={this.state.department === 'all'} onClick={() => this.selectDepartment('all')} >All</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Digital Marketing'} onClick={() => this.selectDepartment('Digital Marketing')} >Digital marketing</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Content'} onClick={() => this.selectDepartment('Content')} >Content</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Design'} onClick={() => this.selectDepartment('Design')} >Design</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Development'} onClick={() => this.selectDepartment('Development')} >Development</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Email'} onClick={() => this.selectDepartment('Email')} >Email</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Senior Leaders'} onClick={() => this.selectDepartment('Senior Leaders')} >Senior team</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'SEO'} onClick={() => this.selectDepartment('SEO')} >SEO</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Social'} onClick={() => this.selectDepartment('Social')} >Social</button>
                        </li>
                        <li>
                            <button data-active={this.state.department === 'Friends of Brew'} onClick={() => this.selectDepartment('Friends of Brew')} >Friends of Brew</button>
                        </li>
                    </ul>
                </div>
                <div className="team-grid">
                    <Row className="big-row">
                        {this.props.members && this.props.members.map(({node}) => {
                            if(node.acf.department.indexOf(this.state.department) !== -1 || this.state.department === 'all') {
                                return (
                                    <Col key={node.id} xs={{span: 12}} md={{span: 8}} lg={{span: 6}} xl={{span: 4}}>
                                        <Link to={`/who-we-are/${node.slug}`} className="member-link">

                                            <span
                                                className="name">{node.title.substr(0, node.title.indexOf(' '))}</span> <span className="job-title">{node.acf.job_title}</span>
                                            <Img style={{height: 320, width: 320}}
                                                 fluid={node.acf.image.localFile.childImageSharp.fluid}/>
                                        </Link>
                                    </Col>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql` query teamMembersQuery {
            allWordpressWpTeamMember(sort: {fields:[menu_order], order: ASC}) {
                edges {
                    node {
                        id
                        slug
                        title
                        acf {
                            department
                            job_title
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 640, maxHeight: 640) {
                                            ... GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`}
        render={(data) => (
            <MemberListing members={data.allWordpressWpTeamMember.edges} />
        )}
    />
)
