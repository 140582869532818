import React from 'react';
import './style.less';
import { Row, Col } from 'antd';

export default class YoutubeEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html: null,
        };
    }

    render() {
        return (
            <div className={this.props.html.html_class + ' module_youtube_embed'} id={this.props.html.html_id}>
                <Row>
                    <Col xs={{span: 24}}>
                        <div className="responsive-video">
                            <iframe src={'https://www.youtube.com/embed/'+this.props.html.video_id}
                                frameBorder="0"
                                allow="accelerometer; encrypted-media;"
                                title={this.props.html.video_title}
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
