import React from 'react';
import './style.less';
import {Row} from 'antd';
import Link from "../../components/Link";
import Img from "gatsby-image";
import DragScroll from 'react-dragscroll';


export default class IconLinks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: null,
        };
    }

    render() {
        return (
            <div className="module_icon_grid">
                <Row>
                    <h3>{this.props.content.title}</h3>
                    <div className="scrollable">
                        <DragScroll className="scroll_inner" height={200} width={100}>
                            <div className="icons">
                                {this.props.content.icons && this.props.content.icons.map((icon) => {
                                    return <Link key={icon.icon.id} className="icon_link" to={icon.linked_page}>
                                            { icon.icon != null ?
                                                <span className="icon_border"><Img fluid={icon.icon.localFile.childImageSharp.fluid} /></span>
                                            : null }
                                        <span className="icon_label">{icon.label}</span>
                                    </Link>
                                })}
                            </div>
                        </DragScroll>
                    </div>
                </Row>
            </div>
        );
    }
}
