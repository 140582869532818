import React from 'react';
import './style.less';
import { Row, Col } from 'antd';

export default class BrewHomeBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            strapline: null,
            x: 0,
            y: 0,
            aniState: 'still',
            lastMovement: Date.now(),
        };
    }

    _onMouseMove(e) {
        this.setState({x: e.pageX, y: e.pageY-80, aniState: 'moving', lastMovement: Date.now() });

        setTimeout(
            function() {
                if (Date.now() - this.state.lastMovement >= 4500) {
                    this.setState({aniState: 'still'});
                }
            }
                .bind(this),
            5000
        );
    }

    render() {
        return (
            <div className="module_brew_home_banner">

                <Row className="big-row">
                    <Col xs={{span: 24}}>
                        <h1>{this.props.strapline}</h1>
                    </Col>
                </Row>

                <span className="ani-trigger" onMouseMove={this._onMouseMove.bind(this)} />

                <span className="dots">
                    <span id="dot-1" data-ani-state={this.state.aniState} />
                    <span id="dot-2" data-ani-state={this.state.aniState} />
                </span>
                <span className="dashes">
                    <span id="dash-1" data-ani-state={this.state.aniState} />
                    <span id="dash-2" data-ani-state={this.state.aniState} />
                    <span id="dash-3" data-ani-state={this.state.aniState} />
                </span>
            </div>
        );
    }
}

