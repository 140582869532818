import React from 'react';
import './style.less';
import {Row, Col, Carousel} from "antd";
import Img from "gatsby-image";
import Link from "../../components/Link";

export default class BrewWorkSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slides: null
        };
    }

    render() {
        return (
            <div className="module_brew_work_slider">
                <div className="slider-strapline">{this.props.slides.slider_title}</div>
                <span className="accent-corner"></span>

                <div className="slide-wrap">
                    <Carousel className="content-slider" dots arrows autoplaySpeed={75000} autoplay swipe swipeToSlide>
                        {this.props.slides.case_studies && this.props.slides.case_studies.map((slide) => {
                            return <div key={'bws-slide-key-'+slide.post_name} className="case-study-copy">
                                <div className="case-study-item" style={{backgroundColor: slide.acf.project_colour}}>
                                    <Row>
                                        <div>
                                            <h2><a href={"/our-work/"+slide.post_name}>{slide.acf.project_strapline}</a></h2>
                                        </div>
                                        <Col xs={{span: 24}}>
                                            <p className="services">{slide.acf.services && slide.acf.services.map((service) => {
                                                return <span key={'brew-work-slider-service-key-'+service.value}>{service.label}</span>
                                            })}</p>
                                            <a href={"/our-work/"+slide.post_name} className="project-image">
                                                <Img fluid={slide.acf.project_image.localFile.childImageSharp.fluid} />
                                            </a>
                                            <Link to={"/our-work/"+slide.post_name} className="more">Read more</Link>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        })}
                    </Carousel>
                </div>

                <Link to="/our-work/" className="arrow-link">All projects</Link>
                <span className="accent-1" />
                <span className="accent-2" />
                <span className="accent-3" />
            </div>
        );
    }
}
