import React from 'react'
import { Form, Icon, Input, Button, List, Select } from 'antd';
const { TextArea } = Input;
const Option = Select.Option;

class CampaignForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateFormAndSend = this.validateFormAndSend.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.state = {
            complete: false,
            status: false,
            errors: [],
            sending: false
        };
    }

    validateFormAndSend(e){
        e.preventDefault();
        // Validate the form
        this.props.form.validateFields((err, values) => {
            if (!err && !this.state.sending) {
                this.setState({ sending: true});
                this.sendMessage(values);
            }
        });
    }

    sendMessage(values){
        return fetch('https://api.brew-systems.co.uk/contact/', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'email' : values.email,
                'name' : values.name,
                'reason' : values.reason,
                'message' : values.message,
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        // }).then(res => {
        //     console.log(res);
        //     return res.json();
        }).then(res => {
            // console.log("In function");
            // console.log(res);
            if (res.statusText == 'OK'){
                // Clear and hide the form
                this.setState({ complete: true, status: 'success', sending: false});
            } else {
                // Display errors
                this.setState({ status: 'error', errors: res.errors, sending: false});
            }
        }).catch(err => err);
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        if (this.state.complete === false) {
            return (
                <Form>
                    {this.state.errors.length ?
                        <List
                            header={<div>Errors</div>}
                            dataSource={this.state.errors}
                            renderItem={item => (<List.Item>{item}</List.Item>)}
                        />
                        : null}

                    <Form.Item label="Your name">
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: 'Please enter your name'
                            }],
                            initialValue: ''
                        })(
                            <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder=""/>
                        )}
                    </Form.Item>

                    <Form.Item label="Contact reason">
                        {getFieldDecorator('reason', {
                            rules: [{
                                required: true,
                                message: 'Please select a contact reason'
                            }],
                            initialValue: 'Please select a contact reason'
                        })(
                            <Select>
                                <Option value="new-business">New business</Option>
                                <Option value="general">General enquiry</Option>
                                <Option value="technical">Technical enquiry</Option>
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Message">
                        {getFieldDecorator('message', {
                            rules: [{required: true, message: 'Please provide an overview of the campaign'}],
                            initialValue: ''
                        })(
                            <TextArea rows={4}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Your email address">
                        {getFieldDecorator('email', {
                            rules: [{type: "email", message: "Please enter a valid email address"}, {
                                required: true,
                                message: 'Please enter an email address'
                            }],
                            initialValue: ''
                        })(
                            <Input prefix={<Icon type="inbox" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder=""/>
                        )}
                    </Form.Item>

                    <Button type="primary" htmlType="button" onClick={this.validateFormAndSend}>Get in touch</Button>

                </Form>
            );
        } else {
            return <div>
                <h2>Thanks!</h2>
                <p>Thanks for getting in touch. Get the kettle on and we’ll get back to you as soon as possible.</p>
            </div>
        }
    }
}

export default Form.create()(CampaignForm);
